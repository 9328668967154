<template>
  <v-dialog
    v-model="moreStore.orderModalOpen"
    persistent
    :fullscreen="isMobile"
    :max-width="isMobile ? undefined : 540"
    min-height="480px"
    scrollable
  >
    <v-card color="background" class="jw-dialog">
      <v-toolbar dark color="background" class="jw-dialog__header">
        <v-toolbar-items></v-toolbar-items>
        <v-toolbar-title class="today-adj" data-ko="교회/단체 설정">{{ $t("modal.grplist.title") }}</v-toolbar-title>
        <v-toolbar-items>
          <v-btn icon="jwIcons:svg/close" @click="moreStore.setOrderModal(false)"></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <VCardText class="jw-dialog__body pa-5 pt-4">
        <div class="d-flex mt-3">
          <v-spacer />
          <VBtn variant="flat" height="25" rounded color="#2DA562" class="fs-12 px-3" @click.stop="findMyGroup()" data-ko="우리 교회/단체 찾기">{{
            $t("modal.grplist.btn.find")
          }}</VBtn>
        </div>
        <draggable
          tag="ul"
          :list="moreStore.myGroupList"
          class="jw-order-list jw-order-list--mygrp jw-order-list--wrap pt-4"
          drag-class="dragging"
          ghost-class="ghost"
          handle=".handle"
          item-key="name"
          @start="moreStore.setDrag(true)"
          @end="moreStore.setDrag(false)"
        >
          <template #item="{ element, index }">
            <li class="jw-order-list__item">
              <div class="handle">
                <v-icon>jwIcons:svg/grap-line</v-icon>
              </div>
              <div class="title">{{ element.grpName }}</div>
              <!-- <v-spacer /> -->
              <!-- <div class="category">{{ element.grpCd }}</div> -->
              <!-- <v-btn icon size="small" class="visible" @click="element.displayYn === 'Y' ? element.displayYn = 'N' : element.displayYn = 'Y'">
                <v-icon v-if="element.displayYn === 'Y'">jwIcons:svg/eye</v-icon>
                <v-icon v-else>jwIcons:svg/eye-off</v-icon>
              </v-btn> -->
            </li>
          </template>
        </draggable>
      </VCardText>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useMoreStore } from "~/store/more";
import draggable from "vuedraggable";
import { useDisplay } from "vuetify";
const { smAndDown, xlAndUp } = useDisplay();
const router = useRouter();
const isMobile = computed(() => smAndDown.value);

const moreStore = useMoreStore();
//const { orderModalOpen = false, drag = false, dashboardList = [] } = storeToRefs(moreStore);

function findMyGroup() {
  moreStore.setOrderModal(false);
  router.push("/more/findMyGroup");
}
/*
watch(
  () => moreStore.myGroupList,
  () => {
    console.log("moreStore.myGroupList ====> ", moreStore.myGroupList);
  },
  { deep: true, immediate: true }
);
 */
</script>
